/**
 * Authorize user
 */
export default defineNuxtRouteMiddleware((to) => {
    if (isGuestPage(to) || is404Page(to)) return;

    const { isAuthorised } = useAuthorisationChecker();

    if (!isAuthorised(to.meta))
        return showError({
            statusCode: 404,
        });
});
