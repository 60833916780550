import { default as dashboardOfIqS1pG78Meta } from "/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/dashboard.vue?macro=true";
import { default as entranceUAtK6tLEeCMeta } from "/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/entrance.vue?macro=true";
import { default as indexDk3Rmd9oFyMeta } from "/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/index.vue?macro=true";
import { default as loginonEbPRStK4Meta } from "/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/login.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entranceUAtK6tLEeCMeta?.name ?? "entrance",
    path: "/entrance",
    meta: entranceUAtK6tLEeCMeta || {},
    component: () => import("/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/entrance.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginonEbPRStK4Meta?.name ?? "login",
    path: "/login",
    meta: loginonEbPRStK4Meta || {},
    component: () => import("/home/forge/master.strauss.smake.eu/releases/v1.1.0/pages/login.vue").then(m => m.default || m)
  }
]