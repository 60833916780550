import { config, library } from "@fortawesome/fontawesome-svg-core";
import {
    faBell,
    faCopyright,
    faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import {
    faChevronDown,
    faAngleDown,
    faAngleUp,
    faHexagonImage,
    faAnglesUpDown,
    faAsterisk,
    faPencil,
    faArrowRightFromBracket,
    faCheck,
    faTimes,
    faCircleStar,
    faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

library.add(
    faChevronDown,
    faAngleDown,
    faAngleUp,
    faHexagonImage,
    faAnglesUpDown,
    faBell,
    faCopyright,
    faUser,
    faUsers,
    faAsterisk,
    faPencil,
    faArrowRightFromBracket,
    faCheck,
    faTimes,
    faCaretDown,
    faCircleStar,
);

export default defineNuxtPlugin({
    name: "font-awesome",
    hooks: {
        "app:created"(nuxtApp) {
            nuxtApp.component("FontAwesomeIcon", FontAwesomeIcon);
        },
    },
});
