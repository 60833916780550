export const NuxtPageNames = {
    DASHBOARD: "dashboard",
    INDEX: "index",
    LOGIN: "login",
    ENTRANCE: "entrance",
} as const;

export const GuestPages = [
    NuxtPageNames.ENTRANCE,
    NuxtPageNames.LOGIN,
] as string[];
