export default defineNuxtPlugin({
    name: "font-awesome-kit",
    setup() {
        const scriptName = useRuntimeConfig().public.kitName;
        const script = document.createElement("script");
        script.async = true;
        script.src = `/js/fontawesome/${scriptName}.js`;
        document.head.appendChild(script);
    },
});
